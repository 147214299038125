"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArticlePage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../components/page_path");
var react_router_dom_1 = require("react-router-dom");
var get_article_1 = require("../../../api/calls/news/get_article");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var ArticlePage = function () {
    var _a, _b, _c;
    var _d = (0, react_1.useState)(), publication = _d[0], setPublication = _d[1];
    var params = (0, react_router_dom_1.useParams)();
    var id = params.id, publication_id = params.publication_id;
    var _e = (0, react_1.useState)(), article = _e[0], setArticle = _e[1];
    var _f = (0, react_1.useState)(true), isLoading = _f[0], setIsLoading = _f[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        (0, get_article_1.getArticle)({
            article: id,
            publication: publication_id,
        }).then(function (response) {
            var _a, _b;
            if (response.success) {
                setArticle((_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.article);
                setPublication((_b = response === null || response === void 0 ? void 0 : response.content) === null || _b === void 0 ? void 0 : _b.publication);
                setIsLoading(false);
            }
        });
        //
    }, []);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-4" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "/publications",
                        title: "Publications",
                    },
                    {
                        page: "/articles/".concat(publication_id),
                        title: "".concat((_a = publication === null || publication === void 0 ? void 0 : publication.name) !== null && _a !== void 0 ? _a : "..."),
                    },
                    {
                        page: "",
                        title: article === null || article === void 0 ? void 0 : article.title,
                        active: true,
                    },
                ] }),
            isLoading ? (react_1.default.createElement(doshx_controls_web_1.ShimmerBoxControl, null)) : (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "shadow hover:shadow-lg transition-shadow cursor-pointer", borderColor: "border-transparent" },
                react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "cursor-pointer", label: (_b = article === null || article === void 0 ? void 0 : article.title) !== null && _b !== void 0 ? _b : "", bold: true, size: "large", textColor: "text-secondary" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatDateTime(((_c = article === null || article === void 0 ? void 0 : article.pubDate) === null || _c === void 0 ? void 0 : _c.seconds) * 1000, "MMMM DD, YYYY h:mm a")), size: "small" }),
                    react_1.default.createElement("div", { className: "flex flex-col gap-4 text-sm font-semibold", dangerouslySetInnerHTML: { __html: article === null || article === void 0 ? void 0 : article.description } }),
                    react_1.default.createElement("div", { className: "flex flex-col gap-4", dangerouslySetInnerHTML: { __html: article === null || article === void 0 ? void 0 : article.content } })),
                react_1.default.createElement("div", { className: "flex justify-end gap-4 pt-10" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "View in ".concat(publication === null || publication === void 0 ? void 0 : publication.name), backgroundColor: "bg-primary", size: "xsmall", endIcon: pro_solid_svg_icons_1.faExternalLink, onClick: function () {
                            window.open(article === null || article === void 0 ? void 0 : article.link, "_blank");
                        } })))))));
};
exports.ArticlePage = ArticlePage;
