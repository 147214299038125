"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindStoresPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var nearest_branches_1 = require("../../../api/calls/client/nearest_branches");
var FindStoresPage = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _l = (0, react_1.useState)(false), isGettingBranches = _l[0], setIsGettingBranches = _l[1];
    var _m = (0, react_1.useState)([]), allBranches = _m[0], setAllBranches = _m[1];
    var _o = (0, react_1.useState)([]), plotData = _o[0], setPlotData = _o[1];
    var _p = (0, react_1.useState)(null), selectedAddress = _p[0], setSelectedAddress = _p[1];
    (0, react_1.useEffect)(function () {
        if (selectedAddress && selectedAddress.fullAddress) {
            setIsGettingBranches(true);
            (0, nearest_branches_1.nearest_branches)({
                // order_type: "null",
                size: "5",
                center_coordinates: {
                    longitude: "".concat(selectedAddress.coordinates.lng),
                    latitude: "".concat(selectedAddress.coordinates.lat),
                },
            }).then(function (response) {
                if (response.success) {
                    setAllBranches(response.content);
                    setIsGettingBranches(false);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Could not get nearest branches!",
                        messages: [response.message],
                    }));
                }
            });
        }
    }, [selectedAddress]);
    var getUserLocation = function () {
        setIsGettingBranches(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setSelectedAddress({
                    fullAddress: "User Location",
                    coordinates: {
                        lng: position.coords.longitude,
                        lat: position.coords.latitude,
                    },
                });
            });
            setIsGettingBranches(false);
        }
        else {
            setIsGettingBranches(false);
        }
    };
    (0, react_1.useEffect)(function () {
        getUserLocation();
    }, []);
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d;
        if (allBranches && allBranches.length > 0) {
            var mapPoints = [];
            for (var _i = 0, _e = allBranches !== null && allBranches !== void 0 ? allBranches : []; _i < _e.length; _i++) {
                var branch = _e[_i];
                mapPoints.push({
                    position: {
                        lat: Number((_b = (_a = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.latitude),
                        lng: Number((_d = (_c = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.longitude),
                    },
                    info: branch === null || branch === void 0 ? void 0 : branch.branch.display_name,
                });
            }
            setPlotData(mapPoints);
        }
    }, [allBranches]);
    return (react_1.default.createElement("div", { className: "flex justify-center" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4" },
                react_1.default.createElement("div", { className: "flex-none flex-row lg:flex-col gap-8 lg:max-w-[25rem] px-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Find A Store", textColor: "text-secondary", bold: true, size: "2xlarge" }),
                    react_1.default.createElement("div", { className: "flex flex-col gap-4 shadow-sm rounded-md p-2" },
                        react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                            react_1.default.createElement(doshx_controls_web_1.AddressSearchControl, { label: "Enter a city or surburb", borderColor: "border-[#EEEEEE]", value: selectedAddress, errorText: "", iconColor: "text-primary", onSelectionChange: function (v) {
                                    setSelectedAddress(v);
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Find a store near me", startIcon: pro_solid_svg_icons_1.faLocationCrosshairs, iconColor: "text-primary", textColor: "text-primary", size: "xsmall", backgroundColor: "bg-transparent", onClick: function () {
                                    getUserLocation();
                                } })),
                        isGettingBranches ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small", progressColor: "text-primary" })) : (react_1.default.createElement("div", { className: "flex flex-col gap-2" }, allBranches.map(function (branch, index) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                            return (react_1.default.createElement("div", { className: "", key: index },
                                react_1.default.createElement(doshx_controls_web_1.DividerControl, { addPadding: false, lineColor: "bg-gray-300", orientation: "horizontal" }),
                                react_1.default.createElement("div", { className: " flex flex-col gap-4  w-full pt-2" },
                                    react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center justify-between" },
                                        react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center w-9/12" },
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_a = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _a === void 0 ? void 0 : _a.display_name, textColor: "text-gray-900", bold: true, size: "medium" })),
                                        react_1.default.createElement("div", { className: "flex flex-row gap-2  items-center w-3/12 cursor-pointer", onClick: function () {
                                                var _a, _b, _c, _d;
                                                window.open("http://maps.google.com/maps?daddr=" + ((_b = (_a = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.latitude) + "," + ((_d = (_c = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.longitude), "_blank");
                                            } },
                                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faDiamondTurnRight, size: "1x" }),
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Directions", textColor: "text-primary", size: "xsmall" }))),
                                    react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center justify-between w-full" },
                                        react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center w-9/12" },
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_c = (_b = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _b === void 0 ? void 0 : _b.address) === null || _c === void 0 ? void 0 : _c.full_address, textColor: "text-gray-600", size: "small" })),
                                        react_1.default.createElement("div", { className: "flex flex-row gap-1 items-center w-3/12" },
                                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faLocationArrow, size: "xs" }),
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(branch === null || branch === void 0 ? void 0 : branch.distance, " KM"), textColor: "text-secondary", size: "xsmall" }))),
                                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: (_d = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _d === void 0 ? void 0 : _d.contact_number, icon: pro_solid_svg_icons_1.faPhone, iconColor: "text-secondary", textColor: "text-primary", size: "small", onClick: function () {
                                            var _a;
                                            window.open("tel:".concat((_a = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _a === void 0 ? void 0 : _a.contact_number), "_blank");
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: (_e = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _e === void 0 ? void 0 : _e.email, icon: pro_solid_svg_icons_1.faEnvelope, iconColor: "text-secondary", textColor: "text-primary", size: "small", onClick: function () {
                                            var _a;
                                            window.open("mailto:".concat((_a = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _a === void 0 ? void 0 : _a.email, "}"), "_blank");
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.AccordionControl, { colors: {
                                            titleTextColor: "text-success",
                                            titleBackgroundColor: "bg-transparent",
                                            contentTextColor: "text-secondary",
                                            contentBackgroundColor: "bg-white",
                                            borderColor: "border-transparent",
                                            divideColor: "divide-gray-200",
                                        }, addHeaderPadding: false, addContentPadding: false, arrowSide: "right", items: [
                                            {
                                                expanded: false,
                                                header: (react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center w-full " },
                                                    react_1.default.createElement("div", { className: "w-.5/12" },
                                                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faClock, size: "xs" })),
                                                    react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center w-11/12 justify-between" },
                                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_g = (_f = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _f === void 0 ? void 0 : _f.working_hours.find(function (x) { return x.day === new Date().toLocaleString("default", { weekday: "long" }); })) === null || _g === void 0 ? void 0 : _g.day, textColor: "text-success", size: "small" }),
                                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Open ".concat((_j = (_h = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _h === void 0 ? void 0 : _h.working_hours.find(function (x) { return x.day === new Date().toLocaleString("default", { weekday: "long" }); })) === null || _j === void 0 ? void 0 : _j.from, " - ").concat((_l = (_k = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _k === void 0 ? void 0 : _k.working_hours.find(function (x) { return x.day === new Date().toLocaleString("default", { weekday: "long" }); })) === null || _l === void 0 ? void 0 : _l.to), textColor: "text-success", size: "small" })))),
                                                content: (react_1.default.createElement("div", { className: "" }, (_o = (_m = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _m === void 0 ? void 0 : _m.working_hours) === null || _o === void 0 ? void 0 : _o.map(function (hours, hours_index) {
                                                    return (react_1.default.createElement("div", { className: "flex flex-row gap-2 items-center justify-between", key: hours_index },
                                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: hours === null || hours === void 0 ? void 0 : hours.day, textColor: "text-secondary", size: "small" }),
                                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(hours === null || hours === void 0 ? void 0 : hours.from, " - ").concat(hours === null || hours === void 0 ? void 0 : hours.to), textColor: "text-secondary", size: "small" })));
                                                }))),
                                            },
                                        ] }),
                                    react_1.default.createElement("div", { className: "flex flex-row justify-center " },
                                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "VIEW STORE", backgroundColor: "bg-primary", textColor: "text-tertiary", size: "xsmall", onClick: function () {
                                                var _a;
                                                navigate("/view-store/".concat((_a = branch === null || branch === void 0 ? void 0 : branch.branch) === null || _a === void 0 ? void 0 : _a._id));
                                            } })))));
                        }))))),
                react_1.default.createElement("div", { className: "flex-1 h-[50vh]" },
                    react_1.default.createElement(doshx_controls_web_1.MapControl, { controlSize: "large", zoom: 8, center: {
                            //Center By: Location of Closest Branch, User Location, Of Winhoek Central
                            lat: Number((_e = (_c = (_b = (_a = plotData[0]) === null || _a === void 0 ? void 0 : _a.position) === null || _b === void 0 ? void 0 : _b.lat) !== null && _c !== void 0 ? _c : (_d = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.coordinates) === null || _d === void 0 ? void 0 : _d.lat) !== null && _e !== void 0 ? _e : -22.5635767),
                            lng: Number((_k = (_h = (_g = (_f = plotData[0]) === null || _f === void 0 ? void 0 : _f.position) === null || _g === void 0 ? void 0 : _g.lng) !== null && _h !== void 0 ? _h : (_j = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.coordinates) === null || _j === void 0 ? void 0 : _j.lng) !== null && _k !== void 0 ? _k : 16.9798253),
                        }, markers: plotData }))))));
};
exports.FindStoresPage = FindStoresPage;
