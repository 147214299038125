"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfilePage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var user_1 = require("../../../reducers/slices/user");
var main_1 = require("../../../reducers/slices/main");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var address_1 = require("./address");
var details_1 = require("./details");
var additional_info_1 = require("./additional_info");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var sampleAppController_1 = require("../../../../facetec/src/sampleAppController");
var ProfilePage = function () {
    var _a, _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return (react_1.default.createElement("div", { className: "grid w-96 h-full items-center overflow-y-auto" },
        react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement("div", { className: "flex flex-row gap-2 text-5xl items-center justify-center clear-both" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faUserCircle }))),
            react_1.default.createElement("div", { className: "flex flex-col justify-center items-center" },
                react_1.default.createElement("div", { className: "flex flex-col items-center gap-2" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Profile", size: "2xlarge", textColor: "text-secondary", bold: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(user.name, " ").concat(user.surname), size: "xsmall", textColor: "text-secondary" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(user.email), size: "xsmall", textColor: "text-secondary" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "(".concat((_a = user.cell_number) === null || _a === void 0 ? void 0 : _a.country_code, ") ").concat((_b = user.cell_number) === null || _b === void 0 ? void 0 : _b.phone_number), size: "xsmall", textColor: "text-secondary" })),
                react_1.default.createElement("div", { className: "pt-4 flex flex-row gap-4 justify-center self-stretch" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Loans", backgroundColor: "bg-transparent", size: "xsmall", textColor: "text-secondary", iconColor: "text-secondary", startIcon: pro_light_svg_icons_1.faMoneyBillTrendUp, onClick: function () {
                            dispatch((0, main_1.setDrawerOpen)(false));
                            navigate("/loans");
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Orders History", backgroundColor: "bg-transparent", size: "xsmall", textColor: "text-secondary", iconColor: "text-secondary", startIcon: pro_solid_svg_icons_1.faChartBar, onClick: function () {
                            dispatch((0, main_1.setDrawerOpen)(false));
                            navigate("/orders");
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Wishlist", backgroundColor: "bg-transparent", size: "xsmall", textColor: "text-secondary", iconColor: "text-secondary", startIcon: pro_solid_svg_icons_1.faHeart, onClick: function () {
                            dispatch((0, main_1.setDrawerOpen)(false));
                            navigate("/wishlist");
                        } }))),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(details_1.ProfileDetailsWidget, null),
                react_1.default.createElement(address_1.ProfileAddressWidget, null),
                react_1.default.createElement(additional_info_1.AdditionalInfoWidget, null)),
            user.SumSubApproved ? null : (react_1.default.createElement("div", { className: "w-48" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl
                // loading={isLoading}
                , { 
                    // loading={isLoading}
                    label: "Verify Identity", backgroundColor: "bg-secondary", onClick: function () {
                        // setIsLoading(true);
                        sampleAppController_1.SampleApp.onPhotoIDMatchPressed();
                    } }))),
            react_1.default.createElement("div", { className: "flex flex-col justify-center items-center" },
                react_1.default.createElement("div", { className: "pt-4 flex flex-row gap-4 justify-center self-stretch" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Log Out", backgroundColor: "bg-secondary", size: "xsmall", startIcon: pro_solid_svg_icons_1.faSignOut, onClick: function () {
                            dispatch((0, user_1.sign_out_thunk)());
                        } }))))));
};
exports.ProfilePage = ProfilePage;
