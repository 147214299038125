"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomePage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var products_1 = require("../../../api/calls/product/products");
var categories_1 = require("../../../api/calls/product/categories");
var selection_1 = require("../../../reducers/slices/selection");
var react_router_dom_1 = require("react-router-dom");
var recently_viewed_1 = require("../../../api/calls/product/recently_viewed");
var home_banner_1 = require("./components/home_banner");
var services_1 = require("./components/services");
var promotion_1 = require("./components/promotion");
var get_app_1 = require("./components/get_app");
var HomePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    // const { products } = useSelector<any, IBasketSliceState>((state) => state.basketState);
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_1.useState)([]), allProducts = _a[0], setAllProducts = _a[1];
    var _b = (0, react_1.useState)([]), categories = _b[0], setCategories = _b[1];
    var _c = (0, react_1.useState)([]), contentSliders = _c[0], setContentSliders = _c[1];
    var _d = (0, react_1.useState)([]), recentlyViewed = _d[0], setRecentlyViewed = _d[1];
    var _e = (0, react_1.useState)(1), currentPage = _e[0], setCurrentPage = _e[1];
    var _f = (0, react_1.useState)(0), totalRecords = _f[0], setTotalRecords = _f[1];
    var _g = (0, react_1.useState)(20), recordsPerPage = _g[0], setRecordsPerPage = _g[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (user === null || user === void 0 ? void 0 : user.uid) {
            (0, recently_viewed_1.getRecentlyViewedProducts)({
                user_id: (_a = user === null || user === void 0 ? void 0 : user.uid) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                // console.log("Recent products fetched", response);
                setRecentlyViewed((_a = response.content) !== null && _a !== void 0 ? _a : []);
            });
        }
    }, [user]);
    (0, react_1.useEffect)(function () {
        (0, categories_1.getCategories)({
            page: "1",
        }).then(function (response) {
            var success = response.success;
            // const cunstomProductCategories: any = [{ image_url: Banner1 }, { image_url: Banner2 }];
            if (success) {
                var product_category_list = response.content.product_category_list;
                var size = 5;
                var filtered_list = product_category_list.filter(function (category) { return category.image_url; }); //.slice(0, size);
                // filtered_list.unshift({ image_url: Banner1 }, { image_url: Banner2 }, { image_url: Banner3 });
                setCategories(filtered_list);
            }
            // else {
            //   setCategories(cunstomProductCategories);
            // }
        });
        (0, products_1.getProducts)({
            page: currentPage.toString(),
            size: recordsPerPage.toString(),
        }).then(function (response) {
            // console.log("products response", response);
            setAllProducts(response.content.product_list);
            setTotalRecords(response.content.count);
        });
    }, [currentPage]);
    (0, react_1.useEffect)(function () {
        var sortedCategoryList = [];
        var _loop_1 = function (index) {
            var filteredCategory = categories[index];
            sortedCategoryList.push({
                content: (react_1.default.createElement("div", { className: "flex cursor-pointer" //justify-center items-end pb-10
                    , onClick: function () {
                        var path = "/shop";
                        var _id = filteredCategory._id, name = filteredCategory.name, description = filteredCategory.description;
                        if (_id && name && description != "advert") {
                            dispatch((0, selection_1.setSelectedCategory)({ item: filteredCategory, level: 0 }));
                            navigate("".concat(path, "/category/").concat(0, "/").concat(filteredCategory._id, "/").concat(encodeURI(filteredCategory.name)));
                        }
                        else {
                            navigate(path);
                        }
                    } },
                    react_1.default.createElement("img", { className: "w-full h-full absolute top-0 left-0", src: "".concat(filteredCategory.image_url) }),
                    filteredCategory.name && filteredCategory.description != "advert" && (react_1.default.createElement("div", { className: "relative\n            top-4 left-4 \n            flex items-center\n            p-4 h-[50px] \n            bg-black/20 shadow-md shadow-black/75\n            rounded-lg" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: filteredCategory.name, textColor: "text-tertiary", size: "2xlarge", bold: true }))))),
            });
        };
        for (var index = 0; index < categories.length; index++) {
            _loop_1(index);
        }
        setContentSliders(sortedCategoryList);
    }, [categories]);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(home_banner_1.HomeBannerComponent, null),
            react_1.default.createElement(services_1.ServicesComponent, null),
            react_1.default.createElement(promotion_1.OnPromotionComponent, null),
            react_1.default.createElement(get_app_1.GetAppComponent, null))));
};
exports.HomePage = HomePage;
