"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotDealsPage = void 0;
var react_1 = __importStar(require("react"));
var products_1 = require("../../api/calls/product/products");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var selection_1 = require("../../reducers/slices/selection");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var add_product_thunk_1 = require("../../reducers/slices/basket/thunks/add_product_thunk");
var symbols_1 = require("../../constants/symbols");
var HotDealsPage = function (props) {
    var _a = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_1.useState)([]), onPromotionProducts = _b[0], setOnPromotionProducts = _b[1];
    var _c = (0, react_1.useState)(0), onPromotionTotalRecords = _c[0], setOnPromotionTotalRecords = _c[1];
    var _d = (0, react_1.useState)(1), onPromotionCurrentPage = _d[0], setOnPromotionCurrentPage = _d[1];
    var _e = (0, react_1.useState)(20), onPromotionRecordsPerPage = _e[0], setOnPromotionRecordsPerPage = _e[1];
    (0, react_1.useEffect)(function () {
        (0, products_1.getProducts)({
            page: onPromotionCurrentPage.toString(),
            size: onPromotionRecordsPerPage.toString(),
            on_promotion: true,
        }).then(function (response) {
            //// console.log("hot-deals-products response", response);
            setOnPromotionProducts(response.content.product_list);
            setOnPromotionTotalRecords(response.content.count);
        });
    }, [onPromotionCurrentPage]);
    (0, react_1.useEffect)(function () {
        //
    }, []);
    var generateDiscountLabel = function (onPromotion, price, onPromotionPrice) {
        var _a;
        if (!onPromotion)
            return "";
        var discountLabelPercentage = (_a = ((parseFloat(price) - parseFloat(onPromotionPrice)) / parseFloat(price)) * 100) !== null && _a !== void 0 ? _a : 0;
        return "".concat(discountLabelPercentage.toFixed(0), "% OFF");
    };
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement("div", { className: "flex items-center p-0" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faFlame, iconColor: "text-primary" //
                    , size: "large", backgroundColor: "bg-transparent", borderColor: "border-white" }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Hot Sale!", textColor: "text-secondary", bold: true, size: "xlarge" //
                 })),
            onPromotionProducts && onPromotionProducts.length == 0 && (react_1.default.createElement("div", { className: "flex flex-col items-center justify-center h-full" },
                react_1.default.createElement("div", { className: "text-sm text-gray-500" }, "There are currently no products on sale!"))),
            onPromotionProducts && onPromotionProducts.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ProductsBigControl, { currencySymbol: symbols_1.currencySymbol, colors: {
                    discount: {
                        textColor: "text-tertiary",
                        // iconColor: `text-primary`,
                        amountColor: "text-primary",
                        backgroundColor: "bg-primary",
                    },
                }, button: {
                    label: "Add to cart",
                    borderColor: "border-primary",
                    onClick: function (product) {
                        dispatch((0, add_product_thunk_1.add_product_thunk)(__assign(__assign({}, product), { quantity: 1 })));
                    },
                }, products: onPromotionProducts.map(function (product, index) {
                    return {
                        index: index,
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        rating: product.rating.average,
                        borderColor: "border-white",
                        shadowColor: "shadow-primary",
                        // borderColor: "border-borders-light",
                        // shadowColor: "shadow-shadows-dark/5",
                        textColor: "text-primary",
                        amountColor: product.on_promotion ? "text-disabled" : "text-primary",
                        // starActiveColor: `text-borders-light`,
                        // starInactiveColor: `text-borders-light`,
                        loaderColor: "text-primary",
                        backgroundColor: "bg-white",
                        discountLabel: generateDiscountLabel(product.on_promotion, product.price, product.promotion_price),
                        discountAmount: product.on_promotion ? product.promotion_price : "",
                    };
                }), onClick: function (product) {
                    //// console.log("product", product);
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                }, pagination: {
                    currentPage: onPromotionCurrentPage,
                    totalRecords: onPromotionTotalRecords,
                    recordsPerPage: onPromotionRecordsPerPage,
                    onClick: function (pageNumber) {
                        setOnPromotionCurrentPage(pageNumber);
                    },
                    // colors: {
                    //   selected: {
                    //     backgroundColor: `bg-secondary`,
                    //     textColor: `text-tertiary`,
                    //     borderColor: `border-borders-light`,
                    //   },
                    //   unselected: {
                    //     backgroundColor: `bg-secondary`,
                    //     textColor: `text-tertiary`,
                    //     borderColor: `border-borders-light`,
                    //   },
                    // },
                } })))));
};
exports.HotDealsPage = HotDealsPage;
