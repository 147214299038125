"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalaryBankingDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../../components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var update_advanced_user_details_1 = require("../../../../api/calls/user/update_advanced_user_details");
var main_1 = require("../../../../reducers/slices/main");
var get_user_details_1 = require("../../../../api/calls/user/get_user_details");
var application_1 = require("../../../../api/calls/loan/application");
var steps_1 = require("./constants/steps");
var route_names_1 = require("../../../../constants/route_names");
var SalaryBankingDetailsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var loan_id = params.loan_id;
    var _a = (0, react_1.useState)(""), accountNumberMessage = _a[0], setAccountNumberMessage = _a[1];
    var _b = (0, react_1.useState)(""), accountTypeMessage = _b[0], setAccountTypeMessage = _b[1];
    var _c = (0, react_1.useState)(""), branchCodeMessage = _c[0], setBranchCodeMessage = _c[1];
    var _d = (0, react_1.useState)(""), bankMessage = _d[0], setBankMessage = _d[1];
    var _e = (0, react_1.useState)(""), accountNumber = _e[0], setAccountNumber = _e[1];
    var _f = (0, react_1.useState)(""), accountType = _f[0], setAccountType = _f[1];
    var _g = (0, react_1.useState)(""), branchCode = _g[0], setBranchCode = _g[1];
    var _h = (0, react_1.useState)(""), bank = _h[0], setBank = _h[1];
    //
    var _j = (0, react_1.useState)(false), isSaving = _j[0], setIsSaving = _j[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    (0, react_1.useEffect)(function () {
        if (user.uid) {
            (0, get_user_details_1.get_user_details)().then(function (response) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                if (response.success) {
                    setAccountNumber((_b = (_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.salary_bank) === null || _b === void 0 ? void 0 : _b.account_number);
                    setAccountType((_d = (_c = response === null || response === void 0 ? void 0 : response.content) === null || _c === void 0 ? void 0 : _c.salary_bank) === null || _d === void 0 ? void 0 : _d.account_type);
                    setBranchCode((_f = (_e = response === null || response === void 0 ? void 0 : response.content) === null || _e === void 0 ? void 0 : _e.salary_bank) === null || _f === void 0 ? void 0 : _f.branch);
                    setBank((_h = (_g = response === null || response === void 0 ? void 0 : response.content) === null || _g === void 0 ? void 0 : _g.salary_bank) === null || _h === void 0 ? void 0 : _h.bank);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
        //
    }, [user]);
    var saveSalaryBanking = function () { return __awaiter(void 0, void 0, void 0, function () {
        var salary_bank, bankingData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateDetails()) return [3 /*break*/, 3];
                    setIsSaving(true);
                    salary_bank = {
                        date_updated: new Date(),
                        account_number: accountNumber,
                        account_type: accountType,
                        bank: bank,
                        branch: branchCode,
                    };
                    return [4 /*yield*/, (0, update_advanced_user_details_1.update_advanced_user_details)({
                            salary_bank: salary_bank,
                        })];
                case 1:
                    bankingData = _a.sent();
                    if (!(bankingData === null || bankingData === void 0 ? void 0 : bankingData.success)) return [3 /*break*/, 3];
                    //Update Loan Details
                    return [4 /*yield*/, (0, application_1.update_loan_application)({
                            uid: loan_id,
                            bankDetails_complete: true,
                            salary_bank_details: salary_bank,
                        }).then(function (response) {
                            if (response.success) {
                                setIsSaving(false);
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_FINANCIAL_DETAILS, "/").concat(loan_id));
                                //navigate next
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            }
                        })];
                case 2:
                    //Update Loan Details
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var previousStep = function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_PAYPOINT_DETAILS, "/").concat(loan_id));
    };
    var validateDetails = function () {
        var messageCount = 0;
        // if (!branchCode) {
        //   messageCount++;
        //   setBranchCodeMessage("Branch code is required.");
        // }
        if (!bank) {
            messageCount++;
            setBankMessage("Select bank.");
        }
        if (!accountNumber) {
            messageCount++;
            setAccountNumberMessage("Account number is required.");
        }
        if (!accountType) {
            messageCount++;
            setAccountTypeMessage("Relationship is required.");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "#",
                        title: "Loan Application",
                    },
                    {
                        title: "Salary Banking Details",
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Salary Banking Details", textColor: "text-secondary", bold: true })),
                react_1.default.createElement("div", { className: "flex flex-col  gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: [
                            { label: "Bank Windhoek", value: "Bank Windhoek" },
                            { label: "First National Bank", value: "First National Bank" },
                            { label: "Nedbank", value: "Nedbank" },
                            { label: "Standard Bank", value: "Standard Bank" },
                            { label: "Trutsco Bank", value: "Trutsco Bank" },
                            { label: "Banco Atlantico", value: "Banco Atlantico" },
                            { label: "Bank BIC Namibia", value: "Bank BIC Namibia" },
                            { label: "Letshego Bank Namibia", value: "Letshego Bank Namibia" },
                        ], errorTextColor: "text-red-500", label: "Bank", size: "small", value: bank, errorText: bankMessage, onChange: function (v) {
                            setBank(v);
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Account Number", value: accountNumber, size: "small", errorText: accountNumberMessage, onChange: function (v) { return setAccountNumber(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: [
                            { label: "Cheque", value: "Cheque" },
                            { label: "Savings", value: "Savings" },
                        ], errorTextColor: "text-red-500", label: "Account Type", size: "small", value: accountType, errorText: accountTypeMessage, onChange: function (v) {
                            setAccountType(v);
                        } })),
                react_1.default.createElement("div", { className: "flex justify-center gap-4 " },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", loading: isSaving, backgroundColor: "bg-primary", size: "small", startIcon: pro_solid_svg_icons_1.faArrowLeft, onClick: function () {
                            previousStep();
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save & Proceed", loading: isSaving, backgroundColor: "bg-secondary", size: "small", endIcon: pro_solid_svg_icons_1.faArrowRight, onClick: function () {
                            saveSalaryBanking();
                        } }))))));
};
exports.SalaryBankingDetailsPage = SalaryBankingDetailsPage;
