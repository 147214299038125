"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignInRequiredComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_duotone_svg_icons_1 = require("@fortawesome/pro-duotone-svg-icons");
var SignInRequiredComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () { }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
        react_1.default.createElement("div", { className: "flex flex-row justify-center items-center gap-2 text-gray-600" },
            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_duotone_svg_icons_1.faUserLock, size: "7x" })),
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Access Denied", size: "4xlarge", textColor: "text-primary", center: true, bold: true }),
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "In order to view the contents of this page, please sign in to your account.", size: "medium", textColor: "text-primary", center: true }),
        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Sign In", size: "medium", backgroundColor: "bg-secondary", textColor: "text-tertiary", onClick: function () {
                dispatch((0, main_1.setDrawerPage)("login"));
                dispatch((0, main_1.setDrawerOpen)(true));
            } })));
};
exports.SignInRequiredComponent = SignInRequiredComponent;
