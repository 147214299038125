"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopPage = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var categories_1 = require("../../../api/calls/product/categories");
var products_1 = require("../../../api/calls/product/products");
var recently_viewed_1 = require("../../../api/calls/product/recently_viewed");
var getRootPath_1 = require("../../../helpers/getRootPath");
var add_product_thunk_1 = require("../../../reducers/slices/basket/thunks/add_product_thunk");
// import { IBasketSliceState } from "../../../reducers/slices/basket";
var selection_1 = require("../../../reducers/slices/selection");
var category_products_1 = require("./category_products");
var page_path_1 = require("../components/page_path");
var symbols_1 = require("../../../constants/symbols");
var ShopPage = function () {
    var location = (0, react_router_dom_1.useLocation)();
    var rootPath = (0, getRootPath_1.getRootPath)(location);
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    // const { products } = useSelector<any, IBasketSliceState>((state) => state.basketState);
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_1.useState)([]), categories = _a[0], setCategories = _a[1];
    var _b = (0, react_1.useState)([]), recentlyViewed = _b[0], setRecentlyViewed = _b[1];
    var _c = (0, react_1.useState)([]), random1Category = _c[0], setRandom1Category = _c[1];
    var _d = (0, react_1.useState)([]), random2Category = _d[0], setRandom2Category = _d[1];
    var _e = (0, react_1.useState)([]), random1Products = _e[0], setRandom1Products = _e[1];
    var _f = (0, react_1.useState)(1), random1TotalRecords = _f[0], setRandom1TotalRecords = _f[1];
    var _g = (0, react_1.useState)(10), random1RecordsPerPage = _g[0], setRandom1RecordsPerPage = _g[1]; //Change this
    var _h = (0, react_1.useState)(1), random1CurrentPage = _h[0], setRandom1CurrentPage = _h[1];
    var _j = (0, react_1.useState)([]), random2Products = _j[0], setRandom2Products = _j[1];
    var _k = (0, react_1.useState)(1), random2TotalRecords = _k[0], setRandom2TotalRecords = _k[1];
    var _l = (0, react_1.useState)(10), random2RecordsPerPage = _l[0], setRandom2RecordsPerPage = _l[1]; //Change this
    var _m = (0, react_1.useState)(1), random2CurrentPage = _m[0], setRandom2CurrentPage = _m[1];
    var basket = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }).basket;
    var _o = (0, react_1.useState)({
        _id: "",
        sku: "",
        title: "",
        sub_title: "",
        description: "",
        price: "0",
        promotion_price: "0",
        on_promotion: false,
        image_url: "",
        image_list: [],
        rating: {
            average: "0",
            count: "0",
        },
    }), retrievedProduct = _o[0], setRetrievedProduct = _o[1];
    var _p = (0, react_1.useState)(false), isAddingToCart = _p[0], setIsAddingToCart = _p[1];
    var _q = (0, react_1.useState)(), productIndex = _q[0], setProductIndex = _q[1];
    (0, react_1.useEffect)(function () {
        (0, categories_1.getCategories)({
            page: "1",
        }).then(function (response) {
            var categoryList = response.content.product_category_list;
            var newCategoryList = [];
            for (var _i = 0, _a = categoryList !== null && categoryList !== void 0 ? categoryList : []; _i < _a.length; _i++) {
                var category = _a[_i];
                if (category.description != "advert") {
                    newCategoryList.push(category);
                }
            }
            setCategories(newCategoryList);
        });
    }, []);
    (0, react_1.useEffect)(function () {
        var _a;
        if (user === null || user === void 0 ? void 0 : user.uid) {
            (0, recently_viewed_1.getRecentlyViewedProducts)({
                user_id: (_a = user === null || user === void 0 ? void 0 : user.uid) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                setRecentlyViewed((_a = response.content) !== null && _a !== void 0 ? _a : []);
            });
        }
    }, [user]);
    (0, react_1.useEffect)(function () {
        if (categories && categories.length > 0) {
            var rc1 = categories[Math.floor(Math.random() * categories.length)];
            setRandom1Category(rc1);
            var rc2 = categories[Math.floor(Math.random() * categories.length)];
            do {
                rc2 = categories[Math.floor(Math.random() * categories.length)];
            } while (rc1.name === rc2.name);
            setRandom2Category(rc2);
        }
    }, [categories]);
    (0, react_1.useEffect)(function () {
        if (random1Category === null || random1Category === void 0 ? void 0 : random1Category._id) {
            getRandom1Products(random1Category._id);
        }
    }, [random1Category]);
    (0, react_1.useEffect)(function () {
        if (random1Category === null || random1Category === void 0 ? void 0 : random1Category._id) {
            getRandom1Products(random1Category._id);
        }
    }, [random1CurrentPage]);
    (0, react_1.useEffect)(function () {
        if (random2Category === null || random2Category === void 0 ? void 0 : random2Category._id) {
            getRandom2Products(random2Category._id);
        }
    }, [random2Category]);
    (0, react_1.useEffect)(function () {
        if (random2Category === null || random2Category === void 0 ? void 0 : random2Category._id) {
            getRandom2Products(random2Category._id);
        }
    }, [random2CurrentPage]);
    var getRandom1Products = function (categporyId) {
        (0, products_1.getProducts)({
            category_id: categporyId,
            page: random1CurrentPage.toString(),
            size: random1RecordsPerPage.toString(),
        }).then(function (response) {
            var list = response.content.product_list;
            setRandom1TotalRecords(parseInt(response.content.count));
            setRandom1Products(list.map(function (product) {
                return {
                    _id: product._id,
                    title: product.title,
                    image: product.image_url,
                    amount: product.price,
                    // rating: product.rating.average,
                    borderColor: "border-white",
                    shadowColor: "shadow-primary",
                };
            }));
        });
    };
    var getRandom2Products = function (categporyId) {
        (0, products_1.getProducts)({
            category_id: categporyId,
            page: random2CurrentPage.toString(),
            size: random2RecordsPerPage.toString(),
        }).then(function (response) {
            var list = response.content.product_list;
            setRandom2TotalRecords(parseInt(response.content.count));
            setRandom2Products(list.map(function (product) {
                return {
                    _id: product._id,
                    title: product.title,
                    image: product.image_url,
                    amount: product.price,
                    // rating: product.rating.average,
                    borderColor: "border-white",
                    shadowColor: "shadow-primary",
                };
            }));
        });
    };
    return (react_1.default.createElement("div", { className: "flex flex-col items-center" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        title: "Shop",
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-4" },
                react_1.default.createElement("div", { className: "flex-none flex lg:flex-col gap-8 lg:max-w-[16rem]" },
                    react_1.default.createElement(doshx_controls_web_1.SideNavigationControl, { title: "Filter by category", items: categories.map(function (category, index) {
                            var _a;
                            return ({
                                _id: category._id,
                                title: category.name,
                                items: (_a = category.sub_categories) === null || _a === void 0 ? void 0 : _a.map(function (sub_category, index) { return ({
                                    _id: sub_category._id,
                                    title: sub_category.name,
                                    items: [],
                                }); }),
                            });
                        }), onItemClick: function (item, level) {
                            dispatch((0, selection_1.setSelectedCategory)({ item: item, level: level }));
                            navigate("".concat(rootPath, "/category/").concat(level, "/").concat(item._id, "/").concat(encodeURI(item.title)));
                        } }),
                    react_1.default.createElement("div", { className: "hidden lg:block flex-none" },
                        react_1.default.createElement(doshx_controls_web_1.CommitmentsControl, { commitments: [
                                // { icon: faTruck, title: "Free Delivery", description: "From R40" },
                                // { icon: faStar, title: "Best Quality", description: "Brand" },
                                // { icon: faHistory, title: "1 Year", description: "For Free Return" },
                                // { icon: faEnvelope, title: "Feedback", description: "99% Real Data" },
                                { icon: pro_solid_svg_icons_1.faCreditCard, title: "Payment", description: "Secure" },
                            ] }))),
                react_1.default.createElement("div", { className: "flex-1" },
                    react_1.default.createElement(react_router_dom_1.Routes, null,
                        react_1.default.createElement(react_router_dom_1.Route, { path: "/*", element: react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                                react_1.default.createElement(doshx_controls_web_1.ProductsSmallControl, { currencySymbol: symbols_1.currencySymbol, category: { name: random1Category.name, color: "text-secondary" }, 
                                    // textColor="text-secondary"
                                    // amountColor=""
                                    // ratingColor=""
                                    products: random1Products, onClick: function (product) {
                                        dispatch((0, selection_1.setSelectedProduct)(product));
                                        navigate("/product/".concat(product._id));
                                    }, pagination: {
                                        currentPage: random1CurrentPage,
                                        totalRecords: random1TotalRecords,
                                        recordsPerPage: random1RecordsPerPage,
                                        onClick: function (pageNumber) {
                                            setRandom1CurrentPage(pageNumber);
                                        },
                                    }, button: {
                                        label: "Add to Cart",
                                        borderColor: "border-primary",
                                        onClick: function (product) {
                                            dispatch((0, add_product_thunk_1.add_product_thunk)(__assign(__assign({}, product), { quantity: 1 })));
                                        },
                                    } }),
                                react_1.default.createElement(doshx_controls_web_1.ProductsBigControl, { currencySymbol: symbols_1.currencySymbol, category: {
                                        name: random2Category.name,
                                        color: "text-secondary",
                                    }, products: random2Products, 
                                    // button={{ borderColor: "border-primary", textColor: "text-primary", onClick: () => {} }}
                                    onClick: function (product) {
                                        dispatch((0, selection_1.setSelectedProduct)(product));
                                        navigate("/product/".concat(product._id));
                                    }, pagination: {
                                        currentPage: random2CurrentPage,
                                        totalRecords: random2TotalRecords,
                                        recordsPerPage: random2RecordsPerPage,
                                        onClick: function (pageNumber) {
                                            setRandom2CurrentPage(pageNumber);
                                        },
                                    }, button: {
                                        label: "Add to Cart",
                                        borderColor: "border-primary",
                                        onClick: function (product) {
                                            dispatch((0, add_product_thunk_1.add_product_thunk)(__assign(__assign({}, product), { quantity: 1 })));
                                        },
                                    } })) }),
                        react_1.default.createElement(react_router_dom_1.Route, { path: "/category/:level/:categoryId/:categoryName", element: react_1.default.createElement(category_products_1.CategoryProductsPage, null) })))),
            recentlyViewed && recentlyViewed.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ProductCarouselSmallControl, { category: {
                    name: "Recently viewed",
                    color: "text-secondary",
                }, products: recentlyViewed.map(function (product) {
                    return {
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        rating: product.rating.average,
                    };
                }), onClick: function (product) {
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                } })))));
};
exports.ShopPage = ShopPage;
