const colors = {
  //TODO: add more colors as needed
  primary: "#C10B28",
  secondary: "#000000",
  tertiary: "#FFFFFF",
  disabled: "#B0B0B0",
  success: "#00AA00",
  error: "#AA0000",
  borders: "#888888",
};

module.exports = colors;
