"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagePath = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
// TODO:
// this will basically be able to decide which contol to render (small product control / big product control)
// it will call the api to get the data based on some redux state
var PagePath = function (props) {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var path = props.path;
    (0, react_1.useEffect)(function () { }, []);
    return (react_1.default.createElement("div", { className: "bg-gray-100 flex flex-row p-2 gap-4" }, path && path.length > 0 ? (path.map(function (p, index) {
        return (react_1.default.createElement("div", { onClick: function () {
                navigate(p.page);
            }, key: index },
            react_1.default.createElement("div", { className: " flex flex-row gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "".concat(p.active ? "text-secondary" : "cursor-pointer"), label: p === null || p === void 0 ? void 0 : p.title, textColor: "text-secondary", size: "small" }),
                !p.active ? react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "cursor-pointer", label: "|", textColor: "text-secondary", size: "small" }) : "")));
    })) : (react_1.default.createElement(react_1.default.Fragment, null))));
};
exports.PagePath = PagePath;
