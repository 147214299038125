"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileDetailsWidget = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var update_user_details_1 = require("../../../api/calls/user/update_user_details");
var main_1 = require("../../../reducers/slices/main");
var user_1 = require("../../../reducers/slices/user");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_router_dom_1 = require("react-router-dom");
var password_1 = require("./password");
var functions_1 = require("../../../constants/functions");
var send_otp_1 = require("../../../api/calls/otp/send_otp");
var ProfileDetailsWidget = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _g = (0, react_1.useState)(false), isEditing = _g[0], setIsEditing = _g[1];
    var _h = (0, react_1.useState)(false), isSaving = _h[0], setIsSaving = _h[1];
    var _j = (0, react_1.useState)((_a = user.name) !== null && _a !== void 0 ? _a : ""), name = _j[0], setName = _j[1];
    var _k = (0, react_1.useState)((_b = user.surname) !== null && _b !== void 0 ? _b : ""), surname = _k[0], setSurname = _k[1];
    var _l = (0, react_1.useState)((_c = user.email) !== null && _c !== void 0 ? _c : ""), email = _l[0], setEmail = _l[1];
    var _m = (0, react_1.useState)({
        phone_number: "",
        country_code: "+264",
        country_abbreviation: "NA",
    }), phoneNumber = _m[0], setPhoneNumber = _m[1];
    var _o = (0, react_1.useState)(""), nameMessage = _o[0], setNameMessage = _o[1];
    var _p = (0, react_1.useState)(""), surnameMessage = _p[0], setSurnameMessage = _p[1];
    var _q = (0, react_1.useState)(""), emailMessage = _q[0], setEmailMessage = _q[1];
    var _r = (0, react_1.useState)(""), phoneNumberMessage = _r[0], setPhoneNumberMessage = _r[1];
    var _s = (0, react_1.useState)(""), otpMessage = _s[0], setOTPMessage = _s[1];
    var _t = (0, react_1.useState)(""), generatedOTP = _t[0], setGeneratedOTP = _t[1];
    var _u = (0, react_1.useState)(""), enteredOTP = _u[0], setEnteredOTP = _u[1];
    var _v = (0, react_1.useState)(false), otpVerified = _v[0], setOTPVerified = _v[1];
    var _w = (0, react_1.useState)(null), timer = _w[0], setTimer = _w[1];
    var _x = (0, react_1.useState)(false), phoneNumberHasChanged = _x[0], setPhoneNumberHasChnaged = _x[1];
    var maxCOTPCountdown = parseInt((_d = process.env.OTP_COUNTDOWN) !== null && _d !== void 0 ? _d : "30");
    var _y = (0, react_1.useState)(0), otpCountdown = _y[0], setOTPCountdown = _y[1];
    (0, react_1.useEffect)(function () {
        _updateCell();
    }, [isEditing]);
    var _updateCell = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(user === null || user === void 0 ? void 0 : user.uid)) return [3 /*break*/, 2];
                    _a = setPhoneNumber;
                    return [4 /*yield*/, (0, functions_1.convert_number)(user.cell_number)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [3 /*break*/, 2];
                case 2: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        var _a;
        if (user === null || user === void 0 ? void 0 : user.cell_number) {
            //  Sort Objects
            var oldNum = Object.fromEntries((_a = Object.entries(user === null || user === void 0 ? void 0 : user.cell_number)) === null || _a === void 0 ? void 0 : _a.sort());
            var newNum = Object.fromEntries(Object.entries(phoneNumber).sort());
            setPhoneNumberHasChnaged(JSON.stringify(oldNum) != JSON.stringify(newNum));
        }
        else {
            setPhoneNumberHasChnaged(true);
        }
    }, [phoneNumber]);
    (0, react_1.useEffect)(function () {
        if (phoneNumberHasChanged) {
            if (generatedOTP === enteredOTP) {
                setOTPVerified(true);
            }
            else {
                setOTPVerified(false);
            }
        }
    }, [enteredOTP]);
    (0, react_1.useEffect)(function () {
        return function () {
            if (timer) {
                // console.log("clearing timer");
                clearTimeout(timer);
            }
        };
    }, []);
    var validateDetails = function () {
        var messageCount = 0;
        setNameMessage("");
        setSurnameMessage("");
        setEmailMessage("");
        setPhoneNumberMessage("");
        setOTPMessage("");
        if (!user.name) {
            messageCount++;
            setNameMessage("Name is required.");
        }
        if (!user.surname) {
            messageCount++;
            setSurnameMessage("Surname is required.");
        }
        if (!user.email) {
            messageCount++;
            setEmailMessage("Email is required.");
        }
        if (!(phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.phone_number)) {
            messageCount++;
            setPhoneNumberMessage("Phone number is required.");
        }
        if (generatedOTP != enteredOTP) {
            messageCount++;
            setOTPMessage("OTP is incorrect.");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-col gap-4 mt-8" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Details", size: "large", textColor: "text-secondary" })),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: isEditing ? pro_solid_svg_icons_1.faSave : pro_solid_svg_icons_1.faPen, backgroundColor: isEditing ? "bg-success" : "bg-primary", borderColor: "border-transparent", iconColor: "text-tertiary", size: "xsmall", loading: isSaving, onClick: function () {
                        if (isEditing) {
                            if (phoneNumberHasChanged && !otpVerified) {
                                dispatch((0, main_1.setMessageModal)({ title: "Error", messages: ["Invalid OTP entered"] }));
                                return;
                            }
                            if (validateDetails()) {
                                setIsSaving(true);
                                (0, update_user_details_1.update_user_details)({
                                    name: name,
                                    surname: surname,
                                    email: email,
                                    cell_number: phoneNumber,
                                }).then(function (response) {
                                    setIsSaving(false);
                                    if (response.success) {
                                        setEnteredOTP("");
                                        setGeneratedOTP("");
                                        setOTPVerified(false);
                                        setPhoneNumberHasChnaged(false);
                                        setOTPCountdown(0);
                                        dispatch((0, user_1.update_details_thunk)({
                                            name: name,
                                            surname: surname,
                                            email: email,
                                            cell_number: phoneNumber,
                                        }));
                                        setIsEditing(false);
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                    }
                                });
                            }
                        }
                        else {
                            setIsEditing(true);
                        }
                    } }),
                isEditing && (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faClose, backgroundColor: "bg-primary", borderColor: "border-transparent", iconColor: "text-tertiary", size: "xsmall", onClick: function () {
                        setIsEditing(false);
                    } })))),
        isEditing ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: name, disabled: !isEditing || user.SumSubApproved, size: "small", errorText: nameMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setName(v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: surname, disabled: !isEditing || user.SumSubApproved, size: "small", errorText: surnameMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setSurname(v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: email, disabled: true, size: "small", errorText: emailMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setEmail(v); } }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                    react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Cell Number", value: phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.phone_number, size: "small", errorText: phoneNumberMessage, defaultCountryCode: (_e = phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.country_abbreviation) !== null && _e !== void 0 ? _e : "NA", showExtension: true, showFlag: true, onChange: function (v) {
                                return setPhoneNumber({
                                    phone_number: v,
                                    country_code: phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.country_code,
                                    country_abbreviation: phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.country_abbreviation,
                                });
                            }, onExtensionChange: function (ext, code) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    setPhoneNumber({
                                        phone_number: phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.phone_number,
                                        country_code: ext,
                                        country_abbreviation: code,
                                    });
                                    return [2 /*return*/];
                                });
                            }); } }),
                        generatedOTP && phoneNumberHasChanged && (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "OTP", type: "number", value: enteredOTP, disabled: !isEditing, size: "small", errorText: otpMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setEnteredOTP(v); } }))),
                    phoneNumberHasChanged && (react_1.default.createElement("div", { className: "flex flex-row " }, otpCountdown > 0 ? (react_1.default.createElement("div", { className: "w-10" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: otpCountdown.toString(), size: "small", backgroundColor: "bg-success" }))) : (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faSend, backgroundColor: "bg-success", borderColor: "border-transparent", iconColor: "text-tertiary", size: "small", onClick: function () {
                            var otp = doshx_controls_web_1.Utilities.generateOTP();
                            (0, send_otp_1.sendOTP)({
                                otp: otp,
                                phone: (0, functions_1.formatNumberForSms)(phoneNumber),
                            }).then(function (response) {
                                if (response.eventId) {
                                    //If dev, auto populate OTP
                                    if (process.env.ENVIRONMENT == "DEV") {
                                        setEnteredOTP(otp);
                                    }
                                    setGeneratedOTP(otp);
                                    setOTPCountdown(maxCOTPCountdown);
                                    var timeLeft_1 = maxCOTPCountdown;
                                    if (timer) {
                                        // console.log("clearing timer");
                                        clearTimeout(timer);
                                    }
                                    setTimer(setInterval(function () {
                                        timeLeft_1 = timeLeft_1 - 1;
                                        setOTPCountdown(timeLeft_1);
                                        if (timer && timeLeft_1 === 0) {
                                            // console.log("clearing timer");
                                            clearTimeout(timer);
                                        }
                                    }, 1000));
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: response.message }));
                                }
                            });
                        } })))))),
            react_1.default.createElement(password_1.ProfilePasswordWidget, null))) : (react_1.default.createElement("div", { className: "flex flex-col justify-center gap-4" },
            react_1.default.createElement("div", { className: "h-12 p-3 flex flex-row gap-4 bg-white rounded-lg justify-start items-center cursor-pointer shadow-md", onClick: function () {
                    setIsEditing(true);
                } },
                react_1.default.createElement("span", { className: " text-secondary " },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPencil })),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Edit Profile", textColor: "text-secondary" })),
            react_1.default.createElement("div", { className: "h-12 p-3 flex flex-row gap-4 bg-white rounded-lg justify-start items-center cursor-pointer shadow-md", onClick: function () {
                    dispatch((0, main_1.setDrawerOpen)(false));
                    navigate("/contact-us");
                } },
                react_1.default.createElement("span", { className: " text-secondary " },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPhonePlus })),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Contact Us", textColor: "text-secondary" }))))));
};
exports.ProfileDetailsWidget = ProfileDetailsWidget;
