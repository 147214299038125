"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinancialDetails = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../../components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var application_1 = require("../../../../api/calls/loan/application");
var react_router_dom_1 = require("react-router-dom");
var main_1 = require("../../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var update_advanced_user_details_1 = require("../../../../api/calls/user/update_advanced_user_details");
var get_user_details_1 = require("../../../../api/calls/user/get_user_details");
var steps_1 = require("./constants/steps");
var route_names_1 = require("../../../../constants/route_names");
var control_colors_1 = require("../../../../constants/control_colors");
var FinancialDetails = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var loan_id = params.loan_id;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_1.useState)(false), isSaving = _a[0], setIsSaving = _a[1];
    var _b = (0, react_1.useState)({
        preferred_payday: "25",
        payment_frequency: "Monthly",
        electricity: 0,
        housing: 0,
        transport: 0,
        education: 0,
        groceries_and_other: 0,
    }), financialDetails = _b[0], setFinancialDetails = _b[1];
    var _c = (0, react_1.useState)([
        {
            key: "",
            value: "",
        },
    ]), fixedMonthlyDeductions = _c[0], setFixedMonthlyDeductions = _c[1];
    var _d = (0, react_1.useState)([
        {
            key: "",
            value: "",
        },
    ]), otherObligations = _d[0], setOtherObligations = _d[1];
    var _e = (0, react_1.useState)([
        {
            key: "",
            value: "",
        },
    ]), acccountPayments = _e[0], setAcccountPayments = _e[1];
    (0, react_1.useEffect)(function () {
        if (user.uid) {
            (0, get_user_details_1.get_user_details)().then(function (response) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                if (response.success) {
                    setFinancialDetails((_b = (_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.financial_detail) !== null && _b !== void 0 ? _b : financialDetails);
                    setOtherObligations((_e = (_d = (_c = response === null || response === void 0 ? void 0 : response.content) === null || _c === void 0 ? void 0 : _c.financial_detail) === null || _d === void 0 ? void 0 : _d.other_obligations) !== null && _e !== void 0 ? _e : otherObligations);
                    setAcccountPayments((_h = (_g = (_f = response === null || response === void 0 ? void 0 : response.content) === null || _f === void 0 ? void 0 : _f.financial_detail) === null || _g === void 0 ? void 0 : _g.account_payments) !== null && _h !== void 0 ? _h : acccountPayments);
                    setFixedMonthlyDeductions((_l = (_k = (_j = response === null || response === void 0 ? void 0 : response.content) === null || _j === void 0 ? void 0 : _j.financial_detail) === null || _k === void 0 ? void 0 : _k.fixed_monthly_deductions) !== null && _l !== void 0 ? _l : fixedMonthlyDeductions);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
        //
    }, [user]);
    var getOrdinalSuffix = function (num) {
        var suffixes = ["th", "st", "nd", "rd"];
        var lastDigit = num % 10;
        var secondLastDigit = Math.floor((num % 100) / 10);
        if (secondLastDigit === 1) {
            return suffixes[0]; // If the number ends in 11, 12, or 13, use 'th'
        }
        else {
            return suffixes[lastDigit] || suffixes[0]; // Otherwise, use the appropriate suffix
        }
    };
    var _f = (0, react_1.useState)(function () {
        var terms = [];
        for (var i = 1; i <= 31; i++) {
            var suffix = getOrdinalSuffix(i);
            terms.push({ label: "".concat(i).concat(suffix), value: "".concat(i) });
        }
        return terms;
    }), daysList = _f[0], setDaysList = _f[1];
    var saveLoanDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var financial_detail, financialDetails_;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSaving(true);
                    financial_detail = __assign(__assign({}, financialDetails), { other_obligations: otherObligations, fixed_monthly_deductions: fixedMonthlyDeductions, account_payments: acccountPayments });
                    return [4 /*yield*/, (0, update_advanced_user_details_1.update_advanced_user_details)({
                            financial_detail: financial_detail,
                        })];
                case 1:
                    financialDetails_ = _a.sent();
                    if (!(financialDetails_ === null || financialDetails_ === void 0 ? void 0 : financialDetails_.success)) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, application_1.update_loan_application)({
                            uid: loan_id,
                            financial_complete: true,
                            financial_detail: financial_detail,
                        }).then(function (response) {
                            if (response.success) {
                                setIsSaving(false);
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_DOCUMENTS, "/").concat(loan_id));
                                //navigate next
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            }
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var previousStep = function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_BANKING_DETAILS, "/").concat(loan_id));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "/loans",
                        title: "Loan Application",
                    },
                    {
                        page: "",
                        title: "Financial Details",
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Financial Details", textColor: "text-secondary", bold: true })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Pay Frequency", textColor: "text-secondary", size: "medium", bold: true })),
                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "", colors: control_colors_1.radio_colors, selectedValue: financialDetails.payment_frequency, cols: "grid-cols-3", options: [
                        { label: "Monthly", value: "Monthly" },
                        { label: "Weekly", value: "Weekly" },
                        { label: "2 - Weekly", value: "2 - Weekly" },
                    ], onChange: function (value) {
                        setFinancialDetails(__assign(__assign({}, financialDetails), { payment_frequency: value }));
                    } }),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Detail of pay date", textColor: "text-secondary", size: "medium", bold: true })),
                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: daysList, errorTextColor: "text-red-500", label: "Please select your pay day", size: "small", value: financialDetails.preferred_payday, errorText: "", onChange: function (value) {
                        setFinancialDetails(__assign(__assign({}, financialDetails), { preferred_payday: value }));
                    } }),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Monthly Expenses", textColor: "text-secondary", size: "medium", bold: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "MONTHLY EXPENSES - Not including account payments and payments deducted on payslip and Bank statement.", textColor: "text-secondary", size: "small" })),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Housing", value: financialDetails.housing, size: "small", errorText: "", onChange: function (value) { return setFinancialDetails(__assign(__assign({}, financialDetails), { housing: value })); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Transport", value: financialDetails.transport, size: "small", errorText: "", onChange: function (value) { return setFinancialDetails(__assign(__assign({}, financialDetails), { transport: value })); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Education", value: financialDetails.education, size: "small", errorText: "", onChange: function (value) { return setFinancialDetails(__assign(__assign({}, financialDetails), { education: value })); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Electricity", value: financialDetails.electricity, size: "small", errorText: "", onChange: function (value) { return setFinancialDetails(__assign(__assign({}, financialDetails), { electricity: value })); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Groceries and other expenses", value: financialDetails.groceries_and_other, size: "small", errorText: "", onChange: function (value) { return setFinancialDetails(__assign(__assign({}, financialDetails), { groceries_and_other: value })); } }),
                react_1.default.createElement(doshx_controls_web_1.DividerControl, { orientation: "horizontal" }),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Account Payments", textColor: "text-secondary", size: "medium", bold: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "ACCOUNT PAYMENTS: - Clothing, furniture etc.", textColor: "text-secondary", size: "small" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "- Paid Personally - Not deducted from Bank Account)", textColor: "text-secondary", size: "small" })),
                react_1.default.createElement("div", { className: "w-full flex flex-col gap-4" },
                    acccountPayments.map(function (data, index) {
                        return (react_1.default.createElement("div", { key: index, className: "flex flex-row w-full gap-4 justify-center items-center" },
                            react_1.default.createElement("div", { className: "w-full" },
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Account Payment", value: data.key, size: "small", errorText: "", onChange: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                        var currentData, updatedData;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, acccountPayments];
                                                case 1:
                                                    currentData = _a.sent();
                                                    updatedData = __spreadArray([], currentData, true);
                                                    updatedData[index].key = value;
                                                    setAcccountPayments(updatedData);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } })),
                            react_1.default.createElement("div", { className: "w-full" },
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Amount", value: data.value, size: "small", errorText: "", onChange: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                        var currentData, updatedData;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, acccountPayments];
                                                case 1:
                                                    currentData = _a.sent();
                                                    updatedData = __spreadArray([], currentData, true);
                                                    updatedData[index].value = value;
                                                    setAcccountPayments(updatedData);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } })),
                            acccountPayments.length > 1 ? (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrash, size: "small", backgroundColor: "bg-red-500", iconColor: "text-tertiary", borderColor: "border-transparent", onClick: function () {
                                    var currentData = __spreadArray([], acccountPayments, true);
                                    currentData.splice(index, 1);
                                    setAcccountPayments(currentData);
                                } })) : null));
                    }),
                    react_1.default.createElement("div", { className: "flex justify-end" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { size: "xsmall", iconColor: "text-secondary", backgroundColor: "bg-transparent", textColor: "text-secondary", borderColor: "border-transparent", label: "Add Field", startIcon: pro_solid_svg_icons_1.faPlus, onClick: function () {
                                var acccount_payments = acccountPayments !== null && acccountPayments !== void 0 ? acccountPayments : [];
                                var updatedAccountPayments = __spreadArray(__spreadArray([], acccount_payments, true), [{ key: "", value: "" }], false);
                                setAcccountPayments(updatedAccountPayments);
                            } }))),
                react_1.default.createElement(doshx_controls_web_1.DividerControl, { orientation: "horizontal" }),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Fixed Monthly Deductions from Bank Account", textColor: "text-secondary", size: "medium", bold: true })),
                react_1.default.createElement("div", { className: "w-full flex flex-col gap-4" },
                    fixedMonthlyDeductions.map(function (data, index) {
                        return (react_1.default.createElement("div", { key: index, className: "flex flex-row w-full gap-4 justify-center items-center" },
                            react_1.default.createElement("div", { className: "w-full" },
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Account Payment", value: data.key, size: "small", errorText: "", onChange: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                        var currentData, updatedData;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, fixedMonthlyDeductions];
                                                case 1:
                                                    currentData = _a.sent();
                                                    updatedData = __spreadArray([], currentData, true);
                                                    updatedData[index].key = value;
                                                    setFixedMonthlyDeductions(updatedData);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } })),
                            react_1.default.createElement("div", { className: "w-full" },
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Amount", value: data.value, size: "small", errorText: "", onChange: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                        var currentData, updatedData;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, fixedMonthlyDeductions];
                                                case 1:
                                                    currentData = _a.sent();
                                                    updatedData = __spreadArray([], currentData, true);
                                                    updatedData[index].value = value;
                                                    setFixedMonthlyDeductions(updatedData);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } })),
                            fixedMonthlyDeductions.length > 1 ? (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrash, size: "small", backgroundColor: "bg-red-500", iconColor: "text-tertiary", borderColor: "border-transparent", onClick: function () {
                                    var currentData = __spreadArray([], fixedMonthlyDeductions, true);
                                    currentData.splice(index, 1);
                                    setFixedMonthlyDeductions(currentData);
                                } })) : null));
                    }),
                    react_1.default.createElement("div", { className: "flex justify-end" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { size: "xsmall", iconColor: "text-secondary", backgroundColor: "bg-transparent", textColor: "text-secondary", borderColor: "border-transparent", label: "Add Field", startIcon: pro_solid_svg_icons_1.faPlus, onClick: function () {
                                var acccount_payments = fixedMonthlyDeductions !== null && fixedMonthlyDeductions !== void 0 ? fixedMonthlyDeductions : [];
                                var updatedData = __spreadArray(__spreadArray([], acccount_payments, true), [{ key: "", value: "" }], false);
                                setFixedMonthlyDeductions(updatedData);
                            } }))),
                react_1.default.createElement(doshx_controls_web_1.DividerControl, { orientation: "horizontal" }),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Other Financial Obligations not listed above", textColor: "text-secondary", size: "medium", bold: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "ACCOUNT PAYMENTS: - Clothing, furniture etc.", textColor: "text-secondary", size: "small" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "- Paid Personally - Not deducted from Bank Account)", textColor: "text-secondary", size: "small" })),
                react_1.default.createElement("div", { className: "w-full flex flex-col gap-4" },
                    otherObligations.map(function (data, index) {
                        return (react_1.default.createElement("div", { key: index, className: "flex flex-row w-full gap-4 justify-center items-center" },
                            react_1.default.createElement("div", { className: "w-full" },
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Account Payment", value: data.key, size: "small", errorText: "", onChange: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                        var currentData, updatedData;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, otherObligations];
                                                case 1:
                                                    currentData = _a.sent();
                                                    updatedData = __spreadArray([], currentData, true);
                                                    updatedData[index].key = value;
                                                    setOtherObligations(updatedData);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } })),
                            react_1.default.createElement("div", { className: "w-full" },
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Amount", value: data.value, size: "small", errorText: "", onChange: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                                        var currentData, updatedData;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, otherObligations];
                                                case 1:
                                                    currentData = _a.sent();
                                                    updatedData = __spreadArray([], currentData, true);
                                                    updatedData[index].value = value;
                                                    setOtherObligations(updatedData);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } })),
                            otherObligations.length > 1 ? (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrash, size: "small", backgroundColor: "bg-red-500", iconColor: "text-tertiary", borderColor: "border-transparent", onClick: function () {
                                    var currentData = __spreadArray([], otherObligations, true);
                                    currentData.splice(index, 1);
                                    setOtherObligations(currentData);
                                } })) : null));
                    }),
                    react_1.default.createElement("div", { className: "flex justify-end" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { size: "xsmall", iconColor: "text-secondary", backgroundColor: "bg-transparent", textColor: "text-secondary", borderColor: "border-transparent", label: "Add Field", startIcon: pro_solid_svg_icons_1.faPlus, onClick: function () {
                                var acccount_payments = otherObligations !== null && otherObligations !== void 0 ? otherObligations : [];
                                var updatedData = __spreadArray(__spreadArray([], acccount_payments, true), [{ key: "", value: "" }], false);
                                setOtherObligations(updatedData);
                            } }))),
                react_1.default.createElement("div", { className: "flex justify-center gap-4 " },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Back", loading: isSaving, backgroundColor: "bg-primary", size: "small", startIcon: pro_solid_svg_icons_1.faArrowLeft, onClick: function () {
                            previousStep();
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save & Proceed", backgroundColor: "bg-secondary", size: "small", loading: isSaving, endIcon: pro_solid_svg_icons_1.faArrowRight, onClick: function () {
                            saveLoanDetails();
                        } }))))));
};
exports.FinancialDetails = FinancialDetails;
